<template>
  <col-elem
    v-if="!option.disabled"
    @click.native="selectHandler($event)"
    class="wizard-option-elem typo">
      <svg-elem :name="sprite"></svg-elem>
      <p>{{ option.label }}</p>
  </col-elem>
</template>

<script>

export default {
  name: 'wizard-option-elem',
  props: {
    option: Object
  },
  data() {
    return {}
  },
  computed: {
    sprite () {
      return 'sprite-wizard-' + this.option.key
    },
  },
  methods: {
    selectHandler (Event) {
      Event.stopPropagation()
      this.$emit('select', this.option.key)
    },
  }
}
</script>

<style lang="sass">
.wizard-option-elem
  display: flex !important
  flex-direction: column
  align-items: center
  justify-content: center
  height: m(20)
  width: m(23)
  max-width: m(23)
  margin: m(1)
  color: white()
  background-color: $primary-color
  cursor: pointer
  &:hover
    background-color: $secondary-color
  .svg-elem
    height: 62%
    flex-grow: 2
    fill: white()
  p
    height: 38%
    max-width: 100%
    padding-top: m(1)
    text-align: center
    color: white()
    +font('tool', 'semibold')
    line-height: 1.1
    overflow: hidden

+xs
  .wizard-option-elem
    flex-direction: row
    width: 100%
    max-width: 100%
    height: m(12)
    .svg-elem
      width: 40%
      height: 90%
    p
      align-self: center
      width: 60%
      height: auto
      padding: 0

</style>
