<template>
  <container-elem class="wizard-page">

    <!-- Headline -->
    <row-elem class="headline">
      <col-elem>
        <button-elem
          v-if="currentStepIntern > 1"
          @click="prevStepHandler"
          icon="left"
          class="is-primary is-icon is-medium is-narrow" />
        <h2 class="typo">
          <span class="prefix">{{ headlinePrefix }}</span>{{ headline }}
        </h2>
      </col-elem>
    </row-elem>

    <!-- Result -->
    <template v-if="isResultStepIntern">
      <row-elem class="result-product">
        <wizard-product-elem
          :product="resultProduct"
          :name="$store.state.site.products[resultProduct]"
          :image="true"
          :success="true"
          @select="productSelectHandler"
          @info="productInfoHandler"
          class="is-1-from-6 is-1-from-3-sm" />
      </row-elem>
      <row-elem class="result-text">
        <col-elem class="is-4 is-offset-4 text">
          <text-elem :content="stepContent.copy"></text-elem>
        </col-elem>
      </row-elem>
    </template>

    <!-- Steps -->
    <template v-else>
      <row-elem class="options">
        <wizard-option-elem
          v-for="option in options"
          :key="option.key"
          :option="option"
          @select="nextStepHandler"/>
      </row-elem>
      <row-elem class="is-box progress-bar">
        <col-elem>
          <span class="bar">
            <span :style="progressBarStyle" class="fill"></span>
          </span>
        </col-elem>
      </row-elem>
      <row-elem class="is-multiline product-select">
        <wizard-product-elem
          v-for="product in products"
          :key="product"
          :product="product"
          :name="$store.state.site.products[product]"
          :disabled="productsIntern[product] === false"
          @select="productSelectHandler"
          @info="productInfoHandler"
          class="is-1-from-6 is-1-from-3-sm" />
      </row-elem>
      <row-elem>
        <col-elem class="help">
          <text-elem :content="$store.state.page.content.productselecthelp" />
        </col-elem>
      </row-elem>
    </template>
  </container-elem>
</template>

<script>
import API from '@/services/API'
import Router from '@/services/Router'
import WizardOptionElem from '@/components/elements/WizardOption'
import WizardProductElem from '@/components/elements/WizardProduct'
import { mapGetters } from 'vuex'

export default {
  name: 'wizard-page',
  components: {
    WizardOptionElem,
    WizardProductElem
  },
  data () {
    return {
      productsIntern: {},
      options: [],
      stepContent: {},
      currentStepIntern: 1,
      currentStepCountIntern: 0,
      isResultStepIntern: false
    }
  },
  computed: {
    ...mapGetters({
      currentStep: 'wizard/currentStep',
      isResultStep: 'wizard/isResultStep',
      currentStepSlug: 'wizard/currentStepSlug',
      currentField: 'wizard/currentField',
      currentStepCount: 'wizard/currentStepCount',
      totalStepCount: 'wizard/totalStepCount',
      resultProduct: 'wizard/resultProduct',
      isMetric: 'project/isMetric',
      isImperial: 'project/isImperial'
    }),
    products () {
      return this.$store.getters['products/available']('array')
    },
    headlinePrefix () {
      if (this.isResultStepIntern) {
        return this.stepContent.headlineprefix
      }
      return '0' + this.currentStepCountIntern
    },
    headline () {
      return this.stepContent.headline
    },
    product () {
      var product = this.resultProduct
      if (product) {
        return this.$store.state.site.products[product]
      }
      return ''
    },
    progressBarStyle () {
      var percent = (100 / this.totalStepCount) * this.currentStepCount
      return { width: fn.round(percent, 3) + '%' }
    }
  },
  methods: {
    initWizard () {
      this.$store.dispatch('wizard/init')
        .then(() => {
          this.initStep()
        })
    },
    initStep (route) {
      this.$router.replace({ params: { step: this.currentStepSlug }}).catch(() => {})
      
      // all this "intern fields" are needed for animation @see window.timeout()
      this.currentStepIntern = this.currentStep
      this.currentStepCountIntern = this.currentStepCount
      this.isResultStepIntern = this.isResultStep
      this.productsIntern = fn.clone(this.$store.state.wizard.products)
    },
    initContent () {
      this.stepContent = this.$store.getters.child(this.$route.fullPath).content
      this.options = []
      if (!this.isResultStep) {
        fn.each(this.$store.state.wizard.data[this.currentField].options, (option) => {
          option.label = option.key
          if (fn.has(this.stepContent.options, option.key)) {
            if (fn.has(this.stepContent.options[option.key], 'metric') && this.isMetric) {
              option.label = this.stepContent.options[option.key].metric
            } else if (fn.has(this.stepContent.options[option.key], 'imperial') && this.isImperial) {
              option.label = this.stepContent.options[option.key].imperial
            } else if (fn.has(this.stepContent.options[option.key], 'label')) {
              option.label = this.stepContent.options[option.key].label
            }
          }
          this.options.push(fn.clone(option))
        })
      }
    },
    prevStepHandler () {
       this.$store.dispatch('wizard/prevStep')
        .then(() => {
          if (this.isResultStepIntern) { // no animation because no progress bar visible
            this.initStep()
          } else {
            window.setTimeout(() => {
              this.initStep()
            }, 500)
          }
        })
        .catch(() => {})
    },
    nextStepHandler (key) {
      var params = {}
      params[this.currentField] = key
      this.$store.dispatch('wizard/nextStep', params)
        .then(() => {
          window.setTimeout(() => {
            this.initStep()
          }, 500)
        })
        .catch(() => {})
    },
    productSelectHandler (product) {
      this.goToConfigurator(product)
    },
    productInfoHandler (product) {
      var modal = {
        body: this.$store.state.page.help[product],
        fullscreen: true
      }
      this.$trigger('modal/show', modal)
    },
    buttonConfiguratorHandler () {
      if (this.resultProduct) {
        this.goToConfigurator(this.resultProduct)
      }
    },
    goToConfigurator (product) {
      this.$router.push({ name: 'configurator', params: {
        product: product
      }})
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        if (!this.$store.state.wizard.init || to.params.step === 'step-1') {
          this.initWizard()
        }
        this.initContent()
      }
    }
  }
}
</script>

<style lang="sass">
.wizard-page
  padding-top: m(2)
  .headline
    .col
      display: flex
      align-items: center
      .button
        margin-right: m(2)
      h2
        +font('regular')
        .prefix
          padding-right: m(2)
          +font('bold')
  .options
    display: flex
    justify-content: center
    flex-wrap: wrap
    width: m(102)
    margin: m(2) auto
  .progress-bar
    margin-top: m(4)
    .col
      border-top: 2px solid grey(6)
      padding-top: m(3)
      text-align: center
      .bar
        display: inline-block
        text-align: left
        width: 40%
        height: m(2)
        line-height: 0
        background-color: grey(7)
        border-radius: $radius-rounded
        .fill
          display: inline-block
          width: 0
          height: m(2)
          background-color: $primary-color
          border-top-left-radius: $radius-rounded
          border-bottom-left-radius: $radius-rounded
          transition: width .5s ease
  .product-select
    margin-top: m(3)
  .result-product
    margin-top: m(6)
    justify-content: center
  .result-text
    margin-top: m(3)
    .col
      text-align: center
      *
        +font('tool')
  .help
    text-align: center
    +font('semibold', 'tool')

+sm
  .wizard-page
    .options
      width: auto
      margin: m(2) 0
    .progress-bar
      .col
        .bar
          width: 90%
    .product-select
      .col
        .product-button
          height: m(10)

+xs
  .wizard-page
    .options
      display: block
    .progress-bar
      .col
        .bar
          width: 100%
    .product-select
      .col
        .product-button
          padding-top: m(2)
          padding-bottom: m(1)
          height: auto
        .info-button
          padding-top: m(1)
          padding-bottom: m(1)
</style>