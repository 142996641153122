<template>
  <col-elem
    class="wizard-product-elem">
      <button-elem
        @click="selectHandler()"
        :class="[ productButtonClass ]"
        class="is-medium product-button">
          <svg-elem v-if="image" :name="productSprite"></svg-elem>
          {{ name }}
      </button-elem>
      <button-elem
        @click="infoHandler()"
        icon="info"
        :class="[ productButtonClass ]"
        class="info-button" />
  </col-elem>
</template>

<script>

export default {
  name: 'wizard-product-elem',
  props: {
    product: String,
    name: String,
    success: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    productButtonClass () {
      if (this.disabled) {
        return 'is-disabled'
      } else if(this.success) {
        return 'is-success'
      } else {
        return 'is-primary'
      }
    },
    productSprite () {
      return 'sprite-' + this.$store.getters['products/type'](this.product)
    },
  },
  methods: {
    selectHandler () {
      if (!this.disabled) {
        this.$emit('select', this.product)
      }
    },
    infoHandler () {
      if (!this.disabled) {
        this.$emit('info', this.product)
      }
    },
  }
}
</script>

<style lang="sass">
.wizard-product-elem
  display: flex !important
  flex-direction: column
  .svg-elem
  .product-button
    flex-direction: column
    padding-bottom: m(1)
    min-height: m(10)
    line-height: 1.2
    white-space: normal
    .svg-elem
      width: 80%
      fill: white()
  .info-button
    margin-top: 1px
    &:not(.is-disabled):hover
      background-color: darken($primary-color, 10%)
      border-color: darken($primary-color, 10%)
</style>
